<template>
  <div class="user-type-navbar">
    <ul class="navbar-nav mr-auto links-navbar-nav">
      <li class="nav-item">
        <router-link
          to="/dashboard"
          class="nav-link u-mr-8"
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          >Dashboard</router-link
        >
      </li>
      <li class="nav-item">
        <router-link
          to="/calendar"
          class="nav-link u-mr-8"
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          >Calendar</router-link
        >
      </li>
      <li
        class="nav-item"
        v-if="$can({ key: 'clients', expectedPermission: 'view' })"
      >
        <router-link
          :to="{ name: 'patients' }"
          class="nav-link u-mr-8"
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          >My Clients</router-link
        >
      </li>
      <UnreadAlert />
      <li class="nav-item">
        <router-link
          :to="{ name: 'rooms' }"
          class="nav-link u-mr-8"
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
        >
          Meeting Rooms
        </router-link>
      </li>
      <pending-booking-requests />
      <profile-nav />
    </ul>
    <ul class="navbar-nav icons-navbar-nav search-task-nav">
      <li class="nav-item position-relative search-nav-item desktop-only-force">
        <label
          :data-state="searchState"
          for="search"
          class="jump-label"
          :title="searchState == 'open' ? '' : 'Jump to any client...'"
        >
          <input
            type="text"
            placeholder="Jump to any client..."
            @click="searchState = 'open'"
            v-model="searchKey"
            @blur="searchBlured"
          />
          <i
            :class="searchState == 'open' ? 'fa fa-times' : 'fa fa-search'"
            aria-hidden="true"
          ></i>
          <ul class="search-result_users">
            <li
              class="text-center p-3 h3 m-0"
              v-if="!loading && !filteredUsers.length && searchKey"
            >
              No users found.
            </li>
            <li
              class="text-center p-3 h3 m-0"
              v-if="!loading && !filteredUsers.length && !searchKey"
            >
              No users to search for.
            </li>
            <alert v-if="loading" class="m-0" />
            <li
              v-for="userItem in filteredUsers"
              :key="userItem.username"
              @click="navigateToUser(userItem)"
              class="pointer-cursor"
            >
              <div
                class="
                  d-flex
                  justify-content-between
                  jump-list-item
                  align-items-center
                "
              >
                <div class="jump-list-name">
                  {{ userItem.name }}
                </div>
                <div class="jump-list-role text-bold text-primary">
                  {{ getRoleName(parseInt(userItem.roleId)) }}
                </div>
              </div>
            </li>
          </ul>
        </label>
      </li>
      <li
        class="nav-item nav-theme-color-item pointer-cursor desktop-only-force"
      >
        <div class="nav-item theme-color-item" @click="navigateToProfile">
          <i class="far fa-user fa-lg text-main fa-19" title="My Profile" />
        </div>
      </li>
      <li class="nav-item nav-create-task-item desktop-only-force">
        <div class="nav-create-task">
          <div class="nav-item create-task-item create-task-modal">
            <i
              class="far fa-edit pointer-cursor text-main"
              title="Create Task"
              @click="openCreateTaskModal"
            />
            <sidebar
              :toggle="openModal"
              @close="closeCreateTaskModal"
              class="create-task-modal"
            >
              <Create
                :insideModal="true"
                @saved="taskSaved"
                :noRedirect="true"
                :key="modalKey"
                v-if="openModal"
              ></Create>
            </sidebar>
          </div>
        </div>
      </li>
      <li class="nav-item nav-theme-color-item pointer-cursor">
        <div
          class="nav-item theme-color-item"
          @click="openThemeModal"
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
        >
          <img class="pallete-img" :src="palleteImg" title="Theme" />
        </div>
      </li>
      <pending-notifications class="desktop-only-force" />
    </ul>
  </div>
</template>
<script>
import { default as ProfileNav } from "./Profile";
import { default as Create } from "../../views/tasks/Create";
import { default as UnreadAlert } from "../UnreadAlert.vue";
import { mapState, mapActions } from "vuex";
import PendingBookingRequests from "@/components/PendingBookingRequests.vue";
import PendingNotifications from "@/components/PendingNotifications.vue";

export default {
  components: {
    ProfileNav,
    Create,
    UnreadAlert,
    PendingBookingRequests,
    PendingNotifications,
  },
  data() {
    return {
      openModal: false,
      modalKey: 1,
      searchState: "close",
      searchKey: "",
      loading: true,
      palleteImg: require("@/assets/img/icons/pallete.png"),
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
      allUsers: (state) => state.security.usersWithRoles,
    }),
    filteredUsers: function () {
      if (!this.searchKey) {
        return this.allUsers;
      }
      return (this.allUsers || []).filter(
        (item) =>
          item.name.toLowerCase().indexOf(this.searchKey.toLowerCase()) > -1
      );
    },
  },
  mounted() {
    this.getAllUsers().then(() => {
      this.loading = false;
    });
  },
  methods: {
    ...mapActions({
      getAllUsers: "security/getUsersWithRoles",
    }),
    searchBlured: function () {
      setTimeout(() => {
        this.searchState = "close";
        this.searchKey = "";
      }, 500);
    },
    navigateToProfile: function () {
      this.$router.push({ path: `/providers/${this.user.provider_id}` });
    },
    navigateToUser: function (userItem) {
      if (userItem.roleId == 1 || userItem.roleId == 5) {
        this.$router.push({ path: "/loading" });
        setTimeout(() => {
          this.$router.replace({ path: `/admin/users/${userItem.id}` });
        }, 500);
      } else if (userItem.roleId == 2) {
        this.$router.push({ path: "/loading" });
        setTimeout(() => {
          this.$router.replace({ path: `/providers/${userItem.id}` });
        }, 500);
      } else {
        this.$router.push({ path: "/loading" });
        setTimeout(() => {
          this.$router.replace({ path: `/clients/${userItem.id}` });
        }, 500);
      }
    },
    openThemeModal: function () {
      this.$modal.show("theme-modal");
    },
    openCreateTaskModal: function () {
      this.openModal = true;
    },
    closeCreateTaskModal: function () {
      this.openModal = false;
      window.localStorage.removeItem("task_form");
    },
    taskSaved: function () {
      this.openModal = false;
      window.localStorage.removeItem("task_form");
      this.modalKey++;
    },
    getRoleName: function (roleId) {
      let roleName;
      switch (roleId) {
        case 1:
          roleName = "(Admin)";
          break;
        case 2:
          roleName = "(Provider)";
          break;
        case 5:
          roleName = "(Manager)";
          break;
        default:
          roleName = "(Client)";
          break;
      }
      return roleName;
    },
  },
};
</script>
