<template>
  <div class="user-type-navbar">
    <ul
      class="navbar-nav mr-0 links-navbar-nav"
      :class="user && user.isPatient && 'patient-navbar'"
    >
      <li class="nav-item">
        <router-link
          to="/dashboard"
          class="nav-link u-mr-8"
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          >Dashboard</router-link
        >
      </li>
      <li class="nav-item">
        <router-link
          to="/calendar"
          class="nav-link u-mr-8"
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          >Calendar</router-link
        >
      </li>
      <li class="nav-item">
        <router-link
          :to="{
            name: 'patients.providers',
            params: {
              id: user.patient_id,
            },
          }"
          class="nav-link u-mr-8"
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          >My Providers</router-link
        >
      </li>
      <UnreadAlert />
      <li class="nav-item">
        <router-link
          to="/booking"
          class="nav-link u-mr-8"
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
        >
          Book a Session
        </router-link>
      </li>
      <li class="nav-item">
        <router-link
          to="/booking-requests"
          class="nav-link u-mr-8"
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          >Booking Requests</router-link
        >
      </li>
      <profile-nav />
    </ul>
    <ul class="navbar-nav icons-navbar-nav search-task-nav">
      <li>
        <sidebar
          :toggle="openModal"
          @close="closeCreateTaskModal"
          class="create-task-modal"
        >
          <Create
            :insideModal="true"
            @saved="taskSaved"
            :noRedirect="true"
            :defaultTaskType="7"
            :defaultPriority="2"
            :key="modalKey"
            v-if="openModal"
          ></Create>
        </sidebar>
      </li>
      <li
        class="nav-item nav-theme-color-item pointer-cursor desktop-only-force"
      >
        <div class="nav-item theme-color-item" @click="navigateToProfile">
          <i class="far fa-user fa-lg text-main fa-19" title="My Profile" />
        </div>
      </li>
      <li
        class="nav-item nav-theme-color-item pointer-cursor"
        data-toggle="collapse"
        data-target="#navbarSupportedContent"
      >
        <div class="nav-item theme-color-item" @click="openThemeModal">
          <img class="pallete-img" :src="palleteImg" title="Theme" />
        </div>
      </li>
      <pending-notifications class="desktop-only-force" />
    </ul>
  </div>
</template>
<script>
import { default as ProfileNav } from "./Profile";
import { default as Create } from "../../views/tasks/Create";
import { default as UnreadAlert } from "../UnreadAlert.vue";
import PendingNotifications from "@/components/PendingNotifications.vue";
import { mapState } from "vuex";

export default {
  components: {
    ProfileNav,
    Create,
    UnreadAlert,
    PendingNotifications,
  },
  data() {
    return {
      openModal: false,
      modalKey: 1,
      palleteImg: require("@/assets/img/icons/pallete.png"),
    };
  },
  mounted() {
    window.openCreateTaskModal = this.openCreateTaskModal;
  },
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
    }),
  },
  methods: {
    openThemeModal: function () {
      this.$modal.show("theme-modal");
    },
    openCreateTaskModal: function () {
      this.openModal = true;
    },
    closeCreateTaskModal: function () {
      this.openModal = false;
      window.localStorage.removeItem("task_form");
    },
    taskSaved: function () {
      this.openModal = false;
      window.localStorage.removeItem("task_form");
      this.modalKey++;
    },
    navigateToProfile: function () {
      this.$router.push({ path: `/clients/${this.user.patient_id}` });
    },
  },
};
</script>
