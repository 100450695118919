<template>
  <div class="invoice-item-outer-container">
    <div class="p-6 invoice-item-container position-relative">
      <div class="row">
        <div class="col-5 col-sm-2 mr-2 mr-sm-4 reportCompanyLogo">
          <img
            :src="companyInfo.logo"
            style="max-width: 100%; max-height: 100%; object-fit: contain"
          />
        </div>
        <div class="col-6">
          <h2>
            <b>{{ companyInfo.name }}</b>
          </h2>
          <h4>{{ companyInfo.location }}</h4>
          <h4>{{ companyInfo.phone_number }}</h4>
          <h4>{{ companyInfo.email }}</h4>
          <h4>{{ companyInfo.url }}</h4>
        </div>
      </div>
      <h1 class="mt-4">INVOICE</h1>
      <div class="row">
        <div class="col-4">
          <h4><b>BILL TO:</b></h4>
          <h4>{{ patientInfo.last }}, {{ patientInfo.first }}</h4>
          <h4 v-if="patientInfo.userAddressLine1">
            {{ patientInfo.userAddressLine1 }}
          </h4>
          <h4 v-if="patientInfo.userAddressLine2">
            {{ patientInfo.userAddressLine2 }}
          </h4>
        </div>
        <div class="col-4">
          <h4><b>SHIP TO:</b></h4>
          <h4>{{ patientInfo.last }}, {{ patientInfo.first }}</h4>
          <h4 v-if="patientInfo.userAddressLine1">
            {{ patientInfo.userAddressLine1 }}
          </h4>
          <h4 v-if="patientInfo.userAddressLine2">
            {{ patientInfo.userAddressLine2 }}
          </h4>
        </div>
        <div class="col-4">
          <h4>
            <b>INVOICE # {{ invoice.id }}</b>
          </h4>
          <h4>
            <b>DATE : {{ invoice.created }}</b>
          </h4>
          <h4>
            <b>DUE DATE : {{ invoice.due }}</b>
          </h4>
        </div>
      </div>

      <hr class="mb-5" />

      <table class="table table-striped mt-3 mb-0">
        <thead>
          <tr>
            <th style="width: 30%">Activity</th>
            <th style="width: 30%" class="text-center">Memo</th>
            <th style="width: 10%" class="text-right">Qty</th>
            <th style="width: 10%" class="text-right">Rate</th>
            <th style="width: 10%" class="text-right">Expenses</th>
            <th style="width: 10%" class="text-right">Interest(%)</th>
            <th style="width: 10%" class="text-right">Amount</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="record in billingData"
            :key="record.id"
            :class="`${record.isDeleted ? 'deleted-record' : ''}`"
          >
            <td>
              <div
                v-if="
                  record.appointment &&
                  record.service &&
                  record.appointment.treatment &&
                  !record.service.parent_service_id
                "
              >
                <b>{{ record.appointment.treatment.name }}</b>
                <br />
                {{ record.appointment.treatment.description }}
              </div>
              <div v-else>
                <b> {{ record.service != null ? record.service.name : "" }}</b>
                <br />
                {{ record.service != null ? record.service.description : "" }}
              </div>
            </td>
            <td class="text-center">
              {{
                (record.appointment.memo != null && record.appointment.memo) ||
                ""
              }}
            </td>
            <td class="text-right">
              {{ +parseFloat(record.qty || 0).toFixed(2) }}
            </td>
            <td class="text-right">
              ${{ +parseFloat(record.rate || 0).toFixed(2) }}
            </td>
            <td class="text-right">
              ${{
                `${+parseFloat(
                  (record.appointment.expenses != null &&
                    record.service &&
                    record.service.parent_service_id == null &&
                    record.appointment.expenses) ||
                    0
                ).toFixed(2)}`.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
              }}
            </td>
            <td class="text-right">
              {{
                `${+parseFloat(record.interest_percentage || 0).toFixed(
                  2
                )}`.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
              }}%
            </td>
            <td class="text-right">
              {{
                `${+parseFloat(record.sub_total).toFixed(2)}`.replace(
                  /(\d)(?=(\d{3})+(?!\d))/g,
                  "$1,"
                )
              }}
            </td>
          </tr>
        </tbody>
      </table>

      <hr class="dashed" />

      <div class="row">
        <h3 class="col-8 text-right">Sub Total:</h3>
        <h3 class="col-4 text-right">
          <s class="mr-1" v-if="hasDeleted"
            >${{
              `${+parseFloat(subTotalPaymentWithDeleted || 0).toFixed(
                2
              )}`.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
            }}</s
          >
          ${{
            `${+parseFloat(subTotalPayment || 0).toFixed(2)}`.replace(
              /(\d)(?=(\d{3})+(?!\d))/g,
              "$1,"
            )
          }}
        </h3>
      </div>
      <div class="row">
        <h3 class="col-8 text-right">Interest Amount:</h3>
        <h3 class="col-4 text-right">
          <s class="mr-1" v-if="hasDeleted"
            >${{
              `${+parseFloat(interestAmountWithDeleted || 0).toFixed(
                2
              )}`.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
            }}</s
          >
          ${{
            `${+parseFloat(interestAmount || 0).toFixed(2)}`.replace(
              /(\d)(?=(\d{3})+(?!\d))/g,
              "$1,"
            )
          }}
        </h3>
      </div>
      <div class="row">
        <h3 class="col-8 text-right">Total (inc. tax):</h3>
        <h3 class="col-4 text-right">
          <s class="mr-1" v-if="hasDeleted"
            >${{
              `${+parseFloat(totalPaymentwithDeleted || 0).toFixed(2)}`.replace(
                /(\d)(?=(\d{3})+(?!\d))/g,
                "$1,"
              )
            }}</s
          >
          ${{
            `${+parseFloat(totalPayment || 0).toFixed(2)}`.replace(
              /(\d)(?=(\d{3})+(?!\d))/g,
              "$1,"
            )
          }}
        </h3>
      </div>
      <div class="row">
        <h3 class="col-8 text-right">Balance Due (inc. tax):</h3>
        <h2 class="col-4 text-right">
          <s class="mr-1" v-if="hasDeleted"
            >${{
              `${+parseFloat(totalPaymentwithDeleted || 0).toFixed(2)}`.replace(
                /(\d)(?=(\d{3})+(?!\d))/g,
                "$1,"
              )
            }}</s
          >
          ${{
            `${+parseFloat(totalPayment || 0).toFixed(2)}`.replace(
              /(\d)(?=(\d{3})+(?!\d))/g,
              "$1,"
            )
          }}
        </h2>
      </div>
      <div style="position: absolute; bottom: 16px" v-if="paid">
        <img src="@/assets/img/paid.png" alt="Paid" style="width: 200px" />
      </div>
    </div>
    <div v-if="isInterestCompounded" class="small mt-1 text-muted">
      *Compounded interest is applied to the invoice.
    </div>
  </div>
</template>

<script>
import { Printd } from "printd";
import helpers from "@/utils/helpers";

export default {
  name: "Invoice",
  props: {
    invoiceData: Object,
    hideDeleted: Boolean,
    noPrint: Boolean,
  },
  data() {
    return {
      userID: 0,
      totalPayment: 0,
      totalPaymentwithDeleted: 0,
      subTotalPayment: 0,
      subTotalPaymentWithDeleted: 0,
      interestAmount: 0,
      interestAmountWithDeleted: 0,
      isInterestCompounded: false,
      hasDeleted: false,
      paid: false,
      billingData: [],
      invoice: {
        id: "",
        created: "",
        due: "",
      },
      patientInfo: {
        first: "",
        last: "",
        userAddressLine1: "",
        userAddressLine2: "",
        id: "",
      },
      companyInfo: {
        name: "",
        location: "",
        phone_number: "",
        email: "",
        url: "",
        hst_number: "",
        logo: "",
      },
      cssText: `.reportCompanyLogo img {
                  height: 150px;
                }

                h4 {
                  font-weight: 100;
                }
                hr {
                  border: 0;
                  border-top: 1px solid #000;
                }

                .dashed {
                  border-top: 1px dashed rgba(0, 0, 0, 0.2);
                }
                .deleted-record td,
                .deleted-record b {
                  text-decoration: line-through;
                  }
                `,
    };
  },
  methods: {
    prettyDate: function (date, format) {
      return helpers.prettyDate(date, format);
    },
    print() {
      let printer = new Printd();
      printer.print(this.$el, [
        "https://maxcdn.bootstrapcdn.com/bootstrap/4.5.2/css/bootstrap.min.css",
        this.cssText,
      ]);
      this.$emit("printed");
    },
  },
  watch: {
    invoiceData: async function () {
      this.userID = this.invoiceData.patient_id;

      //Set billing date for each individual activity
      this.billingData = [...this.invoiceData.billingData];
      this.totalPayment = 0;
      this.interestAmount = 0;
      this.subTotalPayment = 0;
      this.totalPaymentwithDeleted = 0;
      this.subTotalPaymentWithDeleted = 0;
      this.interestAmountWithDeleted = 0;
      this.hasDeleted = false;
      this.isInterestCompounded = false;
      this.paid = this.billingData.filter((item) => item.paid_at).length > 0;

      if (this.hideDeleted) {
        this.billingData = this.billingData.filter((item) => !item.isDeleted);
      }

      this.billingData.forEach((record) => {
        if (
          record.service &&
          (record.service.unit == "FT" || record.service.unit == "FLAT")
        ) {
          record.qty = 1;
        }
        this.subTotalPaymentWithDeleted += record.sub_total;
        this.interestAmountWithDeleted += record.interest_amount;
        this.totalPaymentwithDeleted +=
          record.total_without_interest + record.interest_amount;
        this.isInterestCompounded =
          this.isInterestCompounded || record.is_compound;
        if (!record.isDeleted) {
          this.subTotalPayment += record.sub_total;
          this.interestAmount += record.interest_amount;
          this.totalPayment +=
            record.total_without_interest + record.interest_amount;
        } else {
          this.hasDeleted = true;
        }
      });

      let dueDate = new Date(this.invoiceData.totals.created_at);
      dueDate = dueDate.setMonth(dueDate.getMonth() + 1);
      //Format the invoice information
      this.invoice = {
        id: this.invoiceData.totals.id,
        created: this.prettyDate(
          this.invoiceData.totals.created_at,
          "MM/DD/YYYY"
        ),
        due: this.prettyDate(dueDate, "MM/DD/YYYY"),
      };

      //Set the patient information
      this.patientInfo = {
        first: this.invoiceData.patientInfo.first,
        last: this.invoiceData.patientInfo.last,
        userAddressLine1: this.invoiceData.patientInfo.userAddressLine1,
        userAddressLine2: this.invoiceData.patientInfo.userAddressLine2,
        id: this.invoiceData.patientInfo.id,
      };

      //Set the company information
      this.companyInfo = {
        name: this.invoiceData.companyInfo.name,
        location: this.invoiceData.companyInfo.location,
        phone_number: this.invoiceData.companyInfo.phone_number,
        email: this.invoiceData.companyInfo.email,
        url: this.invoiceData.companyInfo.url,
        logo: this.invoiceData.companyInfo.logo,
      };
    },
  },
  updated() {
    if (!this.noPrint) {
      //Once the information has been formatted from the watcher, print.
      this.print();
    }
  },
};
</script>

<style scoped lang="scss">
.reportCompanyLogo {
  img {
    height: 150px;
  }
}
h4 {
  font-weight: 100;
}
hr {
  border: 0;
  border-top: 1px solid #000;
}

.dashed {
  border-top: 1px dashed rgba(0, 0, 0, 0.2);
}
.deleted-record {
  td,
  b {
    text-decoration: line-through;
  }
}

@media print {
  @page {
    margin: auto;
  }
}
</style>
